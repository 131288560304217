<template>
  <div class="main">
    <a-row align="middle" type="flex">
      <a-col :span="12">
        <a-page-header title="系统设置"/>
      </a-col>
      <a-col :span="12">
        <a-page-header title="小程序管理"/>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="8">
        <a-form-model ref="addForm" :label-col="{span: 4}" :gutter="40" :wrapper-col="{span: 19}" :rules="rules" :model="addForm">
          <!-- <a-form-model-item label="屏蔽设置" prop="ban_owner" help="输入后回车即可添加关键词,如 腾讯 + 回车">
            <a-select mode="tags" placeholder="输入关键词" allowClear @change="changeKeyWords" v-model="addForm.ban_owner">
            </a-select>
          </a-form-model-item> -->
          <a-form-model-item label="客服微信号" prop="wx_number" help="预约页面显示的客服微信号">
            <a-input placeholder="请设置客服微信号" v-model="addForm.wx_number" allow-clear/>
          </a-form-model-item>
          <a-form-model-item label="客服二维码" prop="rqcode" help="预约页面显示的客服二维码">
            <a-upload action="https://api.yiyouzz.top/admin/index/upload" list-type="picture" accept="image/*" @preview="handlePreview" @change="indexChange" :headers="headers" :default-file-list="logoList">
              <a-button v-if="addForm.rqcode.length === 0"> <a-icon type="upload" /> upload </a-button>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label=" " :colon="false" style="">
            <a-button type="primary" icon="save" html-type="submit" :loading="postLoading" @click="postForm">保存</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-col>
      <a-col :span="16" style="display: inline-flex;flex-wrap: wrap;">
        <div v-for="(i,key) in wxMiniData" :key="key" class="mini" @click="showModel(i.id)">
          <span>{{i.back}}</span><a-icon type="edit" class="icon"></a-icon>
        </div>
        <div class="add" @click="showModel(0)">
          <span>添加微信小程序</span><a-icon type="plus" class="icon"></a-icon>
        </div>
      </a-col>
    </a-row>
    <a-drawer width="480" placement="right" maskClosable :visible="boxDrawer" @close="hideModel" title="小程序设置" destroyOnClose>
      <a-form :form="miniform" @submit="saveMini" selfUpdate>
      <a-spin tip="Loading..." :spinning="postLoading">
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item >
              <a-input v-decorator="['id', { rules: [{ required: true }] }]" type="hidden" />
            </a-form-item>
            <a-form-item label="简称/备注" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
              <a-input allowClear placeholder="小程序简称/备注" v-decorator="['back', { rules: [{ required: true, message: '请输入小程序简称/备注!' }] }]" />
            </a-form-item>
            <a-form-item label="appId" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
              <a-input allowClear placeholder="小程序 AppID" v-decorator="['appId', { rules: [{ required: true, message: '请输入小程序 AppID!' }] }]" />
            </a-form-item>
            <a-form-item label="appSecret" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
              <a-input allowClear placeholder="AppSecret(小程序密钥)" v-decorator="['appSecret', { rules: [{ required: true, message: '请输入AppSecret(小程序密钥)!' }] }]" />
            </a-form-item>
            <a-form-item label="mchId" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
              <a-input allowClear placeholder="商户平台商户号" v-decorator="['mchId', { rules: [{ required: true, message: '请输入商户平台商户号!' }] }]" />
            </a-form-item>
            <a-form-item label="apiKey" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
              <a-input allowClear placeholder="商户平台api支付key" v-decorator="['apiKey', { rules: [{ required: true, message: '请输入商户平台api支付key!' }] }]" />
            </a-form-item>
            <a-form-item label="起名费用" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
              <a-input allowClear addon-after="元" placeholder="宝宝起名、在线起名的费用，最少0.01元" v-decorator="['qiming_price', { rules: [{ required: true, message: '请设置起名费用,最少0.01元', type:'number', min:0.01, trigger: 'change', transform: (value) => Number(value) }] }]" />
            </a-form-item>
            <a-form-item label="评分费用" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
              <a-input allowClear addon-after="元" placeholder="姓名评分的费用，最少0.01元" v-decorator="['pingfen_price', { rules: [{ required: true, message: '请设置姓名评分费用，最少0.01元', type:'number', min:0.01, trigger: 'change', transform: (value) => Number(value) }] }]" />
            </a-form-item>
            <a-form-item label="起名数量" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
              <a-input allowClear addon-after="个" placeholder="宝宝起名、在线起名的推荐数量,最少50个" v-decorator="['qiming_nums', { rules: [{ required: true, message: '请设置宝宝起名、在线起名的推荐数量', type:'number', min:0.01, trigger: 'change', transform: (value) => Number(value) }] }]" />
            </a-form-item>
            <a-form-item label="屏蔽设置" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
              <a-select mode="tags" placeholder="输入关键词" allowClear v-decorator="['ban_owner']">
              </a-select>
            </a-form-item>
            <a-form-item label="审核通过" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
              <a-date-picker show-time placeholder="预计审核通过时间" valueFormat="YYYY-MM-DD HH:mm:ss" v-decorator="['ok_time', { initialValue:miniform.getFieldValue('ok_time')}]"/>
            </a-form-item>
            <a-form-item label="收费设置" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
              <a-switch checked-children="收费" un-checked-children="免费" v-decorator="['pay_status', { valuePropName : 'checked',initialValue:miniform.getFieldValue('pay_status')}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-spin>
      <div class="footer">
        <a-button :style="{marginRight: '8px'}" @click="hideModel">取消</a-button>
        <a-button type="primary" html-type="submit" :loading="postLoading">保存</a-button>
      </div>
      </a-form>
    </a-drawer>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="addForm.rqcode" />
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //新增表单数据
      addForm: {rqcode:"",wx_number:"",ban_owner: []},
      //表单数据 数据校检规则
      rules: {
        rqcode: [{ required: true, message: "请上传微信客服的二维码",type:'url',trigger: "blur" }],
        wx_number: [{ required: true, message: "请设置微信客服的微信号",trigger: "blur" }],
      },
      logoList:[],//已上传的Logo图片
      previewVisible:false,
      previewImgUrl:null,
      headers: null,
      postLoading: false,
      wxMiniData: [],
      boxDrawer:false,
      miniform:this.$form.createForm(this, { name: "mini_form" }),
    };
  },
  mounted() {
    this.getInfo()
    this.getMiniList()
    this.headers = { authorization:sessionStorage.getItem('token')}
  },
  methods: {
    getInfo(){
      this.$http
        .get("/admin/admin/getSetting")
        .then(res => {
          if(res.data.result === null)return false
          //请求成功后的处理函数
          this.addForm = res.data.result;
          this.logoList.push(
            {
              uid: '-1',
              name: 'xxx',
              status: 'done',
              url: res.data.result.rqcode,
              thumbUrl: res.data.result.rqcode,
            }
          )
        })
        .catch(() => {
          //请求失败后的处理函数
        });
    },
    getMiniList(){
      this.$http
        .get("/admin/admin/getMiniList")
        .then(res => {
          //请求成功后的处理函数
          this.wxMiniData = res.data.result
        })
        .catch(() => {
          //请求失败后的处理函数
        });
    },
    indexChange({file}) {
      if (file.status === "done") {
        if (file.response.code === 200) {
          this.addForm.rqcode = "https://api.yiyouzz.top/" + file.response.result
        }
      } else if (file.status === "error") {
        this.$message.error(file.response.msg);
        this.addForm.rqcode = ""
      } else if (file.status === "removed") {
        this.addForm.rqcode = ""
      }  
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview() {
      this.previewVisible = true;
    },
    changeKeyWords(value){
      this.addForm.ban_owner = value
    },
    postForm() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.postLoading = true;
          let data = new FormData();
          Object.keys(this.addForm).forEach(key => {
            if(key == 'pay_status')data.append(key, +this.addForm[key])
            else data.append(key, this.addForm[key]);
          });
          
          this.$http
            .post("/admin/admin/setSettingV2", data)
            .then(res => {
              //请求成功后的处理函数
              this.postLoading = false;
              this.$notification["success"]({
                message: "系统提示",
                description: res.data.msg,
                duration: 2.5,
                key: 2
              });
            })
            .catch(() => {
              //请求失败后的处理函数
              this.postLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    showModel(id){
      this.boxDrawer = true
      if(id > 0){
        this.postLoading = true;
        this.$http
          .get("/admin/admin/getMiniInfo",{params: {id: id}})
          .then(res => {
            //请求成功后的处理函数
            this.miniform.setFieldsValue(res.data.result);
            this.postLoading = false;
          })
          .catch(() => {
            this.postLoading = false;
          });
      }else {
        this.$nextTick(() => {
          this.miniform.setFieldsValue({id: 0, pay_status: true});
        })
      }
    },
    hideModel(){
      this.boxDrawer = false
    },
    saveMini(e){
      e.preventDefault();
      this.miniform.validateFields((err, values) => {
        if (!err) {
          this.postLoading = true;
          let data = new FormData();
          Object.keys(values).forEach(key => {
            if(key == 'pay_status')data.append(key, +values[key])
            else data.append(key, values[key]);
            //console.log(values[key]);
          });
          
          this.$http
            .post("/admin/admin/saveMiniInfo", data)
            .then(res => {
              //请求成功后的处理函数
              this.postLoading = false;
              this.$notification["success"]({
                message: "系统提示",
                description: res.data.msg,
                duration: 2.5,
                key: 2
              });
              this.hideModel()
              this.getListData(this.listPage)
            })
            .catch(() => {
              //请求失败后的处理函数
              this.postLoading = false;
            });
        }
      });
    }
  }
}
</script>
<style lang="scss" scoped>
  .mini,.add{
    width: 162px;
    height: 34px;
    background-color: #f3f6fb;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 12px;
    margin-bottom: 10px;
    color: #444;
    font-size: 12px;
    padding: 0 12px;
    box-sizing: border-box;
    cursor: pointer;
    > .icon{
      opacity: 0;
    }
    &:hover{
      background-color: #ebf4ff;
      > .icon{
        opacity: 1;
      }
    }
  }
  .add{
    background-color: #ffffff;
    border: 1px solid #d1def5;
  }
  .footer {
    background-color: #ffffff;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    text-align: right;
  }
</style>